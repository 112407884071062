import type { MenuProps } from 'antd';
import { Menu } from 'antd';

import {
  Link,
  useParams
} from "react-router-dom";

// Utilities imports
import { useData } from '../utilities/dataFetcher.js';
import { sidebar_menu_url } from '../utilities/url_constructors.js';



function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

function createLink(id) {
  if (!id) {
    return "/dashboard";
  }
  else {
    return "/dashboard/" + id;
  }
};

function createLabel(id, name){
    return <Link to={createLink(id)}>{name}</Link>;
};

function createSidebarItem(item){
  if (Array.isArray(item.id)) {
    return getItem(item.name, item.name, item.icon, item.id.map(createSidebarItem), null);
  }
  else {
    return getItem(createLabel(item.id, item.name), item.id, item.icon);
  }
};

export const SideBarMenu = () => {

  const {id, timeframe} = useParams();
  
  console.log("id");
  console.log(id);
  
  console.log("timeframe");
  console.log(timeframe);
  
  console.log("test");
  console.log("new test");

  const data = useData(sidebar_menu_url());

  if (!data) {
    return 'Loading';
  }

  const items = data.map(createSidebarItem);

  return(
    <>
      <Menu
          defaultSelectedKeys={[id]}
              mode="inline"
              theme="dark"
              items={items}
          />
      </>);

};