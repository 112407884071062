import React, {useState, useEffect} from 'react';

export function sidebar_menu_url(){
    
	return "sidebar_menu.json";

};

export function timeframe_menu_url(dashboard_id){

	return "DASHBOARDS/DASH_" + dashboard_id + "/timeframe_menu.json";

};

export function query_result_url(url){

	return url;

};

export function query_result_urls(dashboard_id, timeframe){

	return "DASHBOARDS/DASH_" + dashboard_id + "/query_urls/" + timeframe + ".json";

};