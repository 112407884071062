import React, {useState, useEffect} from 'react';
import { Storage } from 'aws-amplify';

export const useStorageLink = (url) => {
  const [state, setState] = useState();

  useEffect(() => {
    const dataFetch = async () => {
      const data = await Storage.get(url, {level: 'private'});

      setState(data);
    };

    dataFetch();
  }, [url]);

  return state;
};