import type { MenuProps } from 'antd';
import { Button, Menu } from 'antd';

// Routing imports
import {
  Outlet,
  useParams,
  Link
} from "react-router-dom";

// Utilities imports
import { useData } from '../utilities/dataFetcher.js'
import { timeframe_menu_url } from '../utilities/url_constructors.js';

// Antdesign imports
import { Layout } from 'antd';
import Title from 'antd/lib/typography/Title';

const { Header, Footer, Sider, Content } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

function createLabel(timeframe, name){

  return (
    <Link to={timeframe}>{name}</Link>
    );

};

function createTimeframeItem(item){
  if (Array.isArray(item.id)) {
    return getItem(item.name, item.name, item.icon, item.id.map(createTimeframeItem), null);
  }
  else {
    return getItem(createLabel(item.id, item.name), item.id, item.icon);
  }
};

export const TimeframeMenu = () => {

  const { id, timeframe } = useParams();

  const data = useData(timeframe_menu_url(id));

  if (!data) {
    return'Loading';
  }

  const items = data.map(createTimeframeItem);

  return(
    <>
    <Header style={{background:"#fff"}}>
                <Menu
                  defaultSelectedKeys={[timeframe]}
                  mode="inline"
                  theme="dark"
                  items={items}
                  mode="horizontal"
                  theme="light" />
    </Header>
    <Outlet />
    </>
    );
};