// Antdesign imports
import { Line } from '@ant-design/plots';

export const MultiLineChart = ({data}) => {

  const config = {
    data,
    xField: 'datetime',
    yField: 'value',
    seriesField: 'category',
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    autoFit: true,
    padding: [50, 20, 20, 50],
    point: {
      size: 4
    },
    connectNulls: true
  };

  return <Line {...config} />;

};