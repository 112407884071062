import {
  useParams
} from "react-router-dom";

import { MultiLineChart } from './multiline.js'

// Antdesign imports
import Title from 'antd/lib/typography/Title';
import { Layout } from 'antd';

// Utilities imports
import { useData } from '../utilities/dataFetcher.js';
import { query_result_urls, query_result_url } from '../utilities/url_constructors.js';

const { Header, Footer, Sider, Content } = Layout;

export const DashboardComponent = ({meta}) => {

  // fetch data
  const data = useData(query_result_url(meta.url));

  // redirect to desired graph, for now default to multilinechart

  if (!data) {
    return (<div>
          <Title level={5} style={{color:'black'}}> { meta.name } </Title>
          { "Loading dashboard" }
        </div>)
  };

  return (<div>
          <Title level={5} style={{color:'black'}}> { meta.name } </Title>
          <MultiLineChart data={data} />
        </div>);
};

export const Dashboard = () => {

  const { id, timeframe } = useParams();
  
  const queries = useData(query_result_urls(id, timeframe));

  if (!queries) {
    return <Title level={3}>{'Loading'}</Title>;
  }

  return(
    <Content>
      {queries.map((query) => < DashboardComponent meta={query} />)}
    </Content>
    );

};