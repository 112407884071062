// Routing imports
import {
  Outlet
} from "react-router-dom";

// Amplify imports
import {
  Button
} from '@aws-amplify/ui-react';

// Components imports
import { SideBarMenu } from './sidebar_menu.js'

// Antdesign imports
import { Layout } from 'antd';
import Title from 'antd/lib/typography/Title';

const { Header, Footer, Sider, Content } = Layout;

export const BasicLayout = ({signOut}) => {

  return (<Layout>
          <Header>
            <Title level={2} style={{color:'white'}}> {"Dashboard"} </Title>
          </Header>
          <Layout>
            <Sider style={{color:'white'}}>
              <Title level={3} style={{color:'white'}}> Menu </Title>
                <SideBarMenu />
            </Sider>
            <Layout>
              < Outlet />
            </Layout>
          </Layout>
          <Footer><Button onClick={signOut}>Sign Out</Button></Footer>
      </Layout>);
};