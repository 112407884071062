import React, {useState, useEffect} from 'react';
import { useStorageLink } from './generateStorageLink.js';

export const useData = (url) => {
  const [state, setState] = useState();
  const link = useStorageLink(url);
  
  useEffect(() => {
    const dataFetch = async () => {
      const data = await (await fetch(link)).json();

      setState(data);
    };
    
    console.log('startin data fetch for link: ' + link);

    dataFetch();
  }, [link]);

  return state;
};