import "@aws-amplify/ui-react/styles.css";
import './App.css';

// Amplify imports
import {
  Button,
  withAuthenticator,
} from '@aws-amplify/ui-react';

// Routing imports
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

// Components imports;
import { BasicLayout } from './components/basic_layout.js'
import { TimeframeMenu } from './components/timeframe_menu.js';
import { Dashboard } from './components/dashboard.js';

const App = ({ signOut }) => {

  return (
    <>
    <div className="App">
      <Router>
        <Routes>
          <Route path="dashboard" element = < BasicLayout signOut={signOut} /> >
            <Route path=":id" element = < TimeframeMenu /> >
              <Route path=":timeframe" element = < Dashboard /> />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
    </>
  );
}

export default withAuthenticator(App);