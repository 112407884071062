/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cognito_identity_pool_id": "eu-west-3:8c4cffe9-1d99-42d6-afea-66fa97292c4c",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_LoemGN8pM",
    "aws_user_pools_web_client_id": "7olo795sk0sgrtf1flnbk3jvlh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplify-dashboard-data-prd161735-prd",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
